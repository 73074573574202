import { isProduction } from 'helpers';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import thunk from 'redux-thunk';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import {
  allocationEpics,
  gameSettingsEpics,
  lpEpics,
  stablecoinEpics,
  stakingEpics,
  tokenEpics,
  vestingEpics,
} from './epics';
import {
  allocationSlice,
  appSlice,
  gameSettingsSlice,
  lpSlice,
  stablecoinSlice,
  stakingSlice,
  tokenSlice,
  vestingSlice,
} from './reducers';

const epicMiddleware = createEpicMiddleware();

export const rootEpic = combineEpics(
  // appEpics,
  tokenEpics,
  vestingEpics,
  stakingEpics,
  lpEpics,
  stablecoinEpics,
  allocationEpics,
  gameSettingsEpics
);

export const store = configureStore({
  reducer: combineReducers({
    app: appSlice.reducer,
    staking: stakingSlice.reducer,
    vesting: vestingSlice.reducer,
    token: tokenSlice.reducer,
    lp: lpSlice.reducer,
    stablecoin: stablecoinSlice.reducer,
    allocation: allocationSlice.reducer,
    gameSettings: gameSettingsSlice.reducer,
  }),
  middleware: [epicMiddleware, thunk],
  // ? Causes errors in console
  devTools: !isProduction(),
});

epicMiddleware.run(rootEpic);

export * from './actions';
export * from './selectors';
export * from './reducers';

export type State = ReturnType<typeof store.getState>;
export default store;
