/* eslint-disable @typescript-eslint/no-unused-vars */
import { SectionHeader } from 'components';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBalances, selectStablecoinPrice } from 'store';

import StakingPools from './StakingPools';

export const Staking: React.FC = () => {
  const stableTokenPrice = useSelector(selectStablecoinPrice);
  const balances = useSelector(selectBalances);
  const description =
    'Add tokens to our farm. Approve, deposit, withdraw and claim rewards. Select one of options presented below.';
  const title = 'Staking';

  return (
    <div className="container staking">
      <div className="container-inner">
        <div className="section-container">
          <SectionHeader title={title} description={description} />
        </div>

        {stableTokenPrice && balances && <StakingPools />}
      </div>
    </div>
  );
};
