import BigNumber from 'bignumber.js';
import { fromWei, toWei } from 'helpers';
import { TokenData, VestingAccountData, VestingPoolVestingData } from 'models';
import { Contract } from 'web3-eth-contract';

export enum VestingPoolType {
  Team = 'Team',
  Public = 'Public',
}

export const isInVestingPool = async (
  connectedAddress: string,
  contract: Contract
): Promise<boolean> => {
  try {
    const accountData: VestingAccountData = await contract.methods
      .getAccountData(connectedAddress)
      .call();

    return new BigNumber(accountData.allocated).isGreaterThan(0);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);

    return false;
  }
};

// allocated - całkowita alokacja użytkownika
// claimed - całkowita kwota zclaimwoana (ale nie zdeponowana do gry)
// left - pozostała kwota alokacji (po uwzględnieniu zclaimowanej i zdeponowanej do gry)
// unlocked - kwota odblokowana od początku vestingu
// claimable - kwota możliwa do zclaimowania teraz
// - kwota którą można zclaimwoać na portfel (claim()) to: claimable
// - kwota którą można wypłacić do gry to: left
// - kwotę już zdeponowaną do gry można obliczyć jako: allocated - left - claimed

export const getVestingPoolData = async (
  connectedAddress: string,
  tokenData: TokenData,
  vestingPoolContract: Contract
): Promise<VestingPoolVestingData> => {
  const accountData: VestingAccountData = await vestingPoolContract.methods
    .getAccountData(connectedAddress)
    .call();
  // Allocated
  const amountAllocatedWei = accountData.allocated;
  const amountAllocated = fromWei(amountAllocatedWei, tokenData.decimals);

  // Claimable
  const amountClaimableWei = accountData.claimable;
  const amountClaimable = fromWei(amountClaimableWei, tokenData.decimals);

  // Claimed
  const amountClaimedWei = accountData.claimed;
  const amountClaimed = fromWei(amountClaimedWei, tokenData.decimals);

  // Left
  const amountLeftWei = accountData.left;
  const amountLeft = fromWei(amountLeftWei, tokenData.decimals);

  return {
    amountAllocated,
    amountClaimable,
    amountLeft,
    amountClaimed,
  };
};

export const postDepositFromVestingToGameBridge = async (
  vestingContract: Contract,
  depositValue: string,
  accountId: string,
  decimals: number,
  connectedAddress: string
): Promise<unknown> => {
  const depositValueWei = toWei(depositValue, decimals);

  return await vestingContract.methods
    .depositToBridge(depositValueWei, accountId)
    .send({
      from: connectedAddress,
    });
};
