import React from 'react';

interface PercentageButtonsProps {
  variant?: string;
  disabled?: boolean;
  applyPercentage: (percentage: number) => void;
}

const percentages = [25, 50, 75, 100];

export const PercentageButtons: React.FC<PercentageButtonsProps> = ({
  variant,
  disabled,
  applyPercentage,
}) => {
  const percentageButtons = percentages.map((percentage, key) => (
    <button
      className="btn btn-ternary percentage-button"
      disabled={disabled}
      onClick={(): void => {
        !disabled && onApplyPercentage(percentage);
      }}
      key={key}
    >
      {`${percentage}%`}
    </button>
  ));

  const onApplyPercentage = (percentage: number): void => {
    applyPercentage(percentage);
  };

  return <div className="percentage-buttons">{percentageButtons}</div>;
};
