import { StakingPoolData } from 'models';
import {
  getStakingPoolsSuccess,
  resetStakingState,
} from 'store/actions/staking.actions';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface StakingState {
  pools: StakingPoolData[];
}

export const initialStakingState: StakingState = {
  pools: null,
};

export const stakingSlice = createSlice<
  StakingState,
  SliceCaseReducers<StakingState>,
  string
>({
  name: 'staking',
  initialState: initialStakingState,
  reducers: {},
  extraReducers: {
    [getStakingPoolsSuccess.type]: (
      state,
      action: PayloadAction<StakingPoolData[]>
    ) => {
      state.pools = action.payload;
    },
    [resetStakingState.type]: (state) => {
      state = initialStakingState;
    },
  },
});
