import { SectionLoader } from 'components';
import {
  AllocationPoolData,
  AllocationPoolSubtype,
  TokenBalances,
  TokenData,
  TokensData,
} from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAllocationPools, selectBalances, selectTokensData } from 'store';

import AllocationPool from './AllocationPool';

interface AllocationPoolsProps {
  poolsSubtype: AllocationPoolSubtype;
}

const AllocationPools: React.FC<AllocationPoolsProps> = ({ poolsSubtype }) => {
  const balances: TokenBalances = useSelector(selectBalances);
  const tokensData: TokensData = useSelector(selectTokensData);
  const allocationPoolsData: AllocationPoolData[] = useSelector(
    selectAllocationPools
  );

  const getAllocationTokenData = (
    allocationPoolData: AllocationPoolData
  ): TokenData => tokensData[allocationPoolData.poolTokenSymbol];

  const getPoolTokenBalance = (
    allocationPoolData: AllocationPoolData
  ): string => balances[allocationPoolData.poolTokenSymbol];

  const allocationPools = allocationPoolsData?.reduce(
    (
      acc: JSX.Element[],
      allocationPoolData: AllocationPoolData,
      index: number
    ) => {
      if (allocationPoolData.poolSubtype === poolsSubtype) {
        acc.push(
          <AllocationPool
            key={`staking-pool-component-${index}`}
            allocationPoolData={allocationPoolData}
            tokensData={tokensData}
            allocationTokenData={getAllocationTokenData(allocationPoolData)}
            balance={getPoolTokenBalance(allocationPoolData)}
          />
        );
      }

      return acc;
    },
    []
  );

  return allocationPools ? (
    <div className="staking-pools">{tokensData.SDT && allocationPools}</div>
  ) : (
    <SectionLoader />
  );
};

export default AllocationPools;
