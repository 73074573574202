import { LPPairsContracts, LPsData, LPTokensPrices } from 'models';
import {
  getLPPairsContractsSuccess,
  getLPsDataSuccess,
  getLPTokensPricesSuccess,
  resetLPState,
} from 'store/actions';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface LPState {
  lpPairsContracts: LPPairsContracts;
  LPTokensPrices: LPTokensPrices;
  lpsData: LPsData;
}

export const initialLPState: LPState = {
  lpPairsContracts: null,
  LPTokensPrices: null,
  lpsData: null,
};

export const lpSlice = createSlice<LPState, SliceCaseReducers<LPState>, string>(
  {
    name: 'lp',
    initialState: initialLPState,
    reducers: {},
    extraReducers: {
      [getLPPairsContractsSuccess.type]: (
        state,
        action: PayloadAction<LPPairsContracts>
      ) => {
        state.lpPairsContracts = action.payload;
      },
      [getLPTokensPricesSuccess.type]: (
        state,
        action: PayloadAction<LPTokensPrices>
      ) => {
        state.LPTokensPrices = action.payload;
      },
      [getLPsDataSuccess.type]: (state, action: PayloadAction<LPsData>) => {
        state.lpsData = action.payload;
      },
      [resetLPState.type]: (state) => {
        state = initialLPState;
      },
    },
  }
);
