import { DepositSource, VestingPoolData } from 'models';
import React from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';

interface GameAccountItemDepositTabContentSourceSelectorProps {
  depositSource: DepositSource;
  loading: boolean;
  vestingPoolsData: VestingPoolData[];
  selectedVestingPoolData: VestingPoolData;
  onSelectVestingPool: (vestingPoolData: VestingPoolData) => void;
  onSetDepositSource: (depositSource: DepositSource) => void;
}

export const GameAccountItemDepositTabContentSourceSelector: React.FC<
  GameAccountItemDepositTabContentSourceSelectorProps
> = ({
  depositSource,
  loading,
  vestingPoolsData,
  selectedVestingPoolData,
  onSelectVestingPool,
  onSetDepositSource,
}) => {
  const vestingDropdownItems = vestingPoolsData.reduce(
    (acc: JSX.Element[], vestingPoolData: VestingPoolData, index: number) => {
      acc.push(
        <Dropdown.Item
          key={`vesting-pool-dropdown-item-${index}`}
          eventKey={index}
          onClick={(): void => onSelectVestingPool(vestingPoolData)}
          active={
            vestingPoolData.vestingPoolContract.options?.address ===
            selectedVestingPoolData?.vestingPoolContract.options?.address
          }
        >
          {vestingPoolData.poolType}
        </Dropdown.Item>
      );

      return acc;
    },
    []
  );

  return (
    <Form>
      <div className="mb-3">
        <Form.Check
          label="Deposit from wallet"
          name="depositSource"
          type="radio"
          id="deposit-source-wallet"
          value="wallet"
          checked={depositSource === 'wallet'}
          onChange={(): void => onSetDepositSource('wallet')}
          disabled={loading}
        />

        <Form.Check
          label={`Deposit from vesting ${
            vestingPoolsData?.length
              ? ''
              : '(You do not have any vesting pools available)'
          }`}
          name="depositSource"
          type="radio"
          id="deposit-source-vesting"
          value="vesting"
          checked={depositSource === 'vesting'}
          onChange={(): void => onSetDepositSource('vesting')}
          disabled={loading || !vestingDropdownItems?.length}
        />
      </div>

      {depositSource === 'vesting' ? (
        <DropdownButton
          className="mb-16"
          variant="secondary"
          id="dropdown-basic-button"
          title={
            selectedVestingPoolData
              ? selectedVestingPoolData.poolType
              : 'Select Vesting Pool'
          }
          disabled={loading}
        >
          {vestingDropdownItems}
        </DropdownButton>
      ) : (
        ''
      )}
    </Form>
  );
};
