import { GameAccount, LoginResponse } from 'models';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  delay,
  filter,
  first,
  mergeMap,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { GameSettingsService, NftService } from 'services';
import * as gameSettingsActions from 'store/actions/game-settings.actions';
import {
  selectAuthToken,
  selectConnectedAddress,
  selectNftContract,
  selectWeb3,
} from 'store/selectors';

import { Action } from '@reduxjs/toolkit';

export const login = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(gameSettingsActions.login.match),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const connectedAddress = selectConnectedAddress(state);
      const web3 = selectWeb3(state);

      return GameSettingsService.login(connectedAddress, web3).pipe(
        first(),
        delay(500),
        switchMap((loginResponse: LoginResponse) => [
          gameSettingsActions.loginSuccess(loginResponse),
        ]),
        catchError((error) => [
          gameSettingsActions.loginFailure(),
          gameSettingsActions.setGameSettingsErrors({ login: error.message }),
        ])
      );
    })
  );

export const getGameAccounts = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(gameSettingsActions.getGameAccounts.match),
    withLatestFrom(state$),
    switchMap(([, state]) => {
      const authToken = selectAuthToken(state);

      return GameSettingsService.getGameAccounts(authToken).pipe(
        first(),
        switchMap((gameAccounts: GameAccount[]) => [
          gameSettingsActions.getGameAccountsSuccess(gameAccounts),
        ]),
        catchError((error) => [
          gameSettingsActions.getGameAccountsFailure(),
          gameSettingsActions.setGameSettingsErrors({
            gameAccount: error.message,
          }),
        ])
      );
    })
  );

export const createGameAccount = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(gameSettingsActions.createGameAccount.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const authToken = selectAuthToken(state);
      const web3 = selectWeb3(state);
      const connectedAddress = selectConnectedAddress(state);

      return GameSettingsService.createGameAccount(
        connectedAddress,
        web3,
        authToken,
        payload
      ).pipe(
        first(),
        switchMap(() => [
          gameSettingsActions.createGameAccountSuccess(),
          gameSettingsActions.getGameAccounts(),
        ]),
        catchError((error) => [
          gameSettingsActions.createGameAccountFailure(),
          gameSettingsActions.setGameSettingsErrors({
            gameAccount: error.message,
          }),
        ])
      );
    })
  );

export const getNfts = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(gameSettingsActions.getNfts.match),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) => {
      const nftContract = selectNftContract(state);
      const connectedAddress = selectConnectedAddress(state);

      return NftService.getNftBalance(connectedAddress, nftContract).pipe(
        mergeMap((nftBalance) =>
          NftService.getNftsIds(
            connectedAddress,
            +nftBalance,
            nftContract
          ).pipe(
            mergeMap((nftIds) =>
              NftService.getAllNfts(nftIds).pipe(
                switchMap((nfts) => [gameSettingsActions.getNftsSuccess(nfts)])
              )
            )
          )
        )
      );
    }),
    // TODO: Double check if it should not be one level up
    catchError((error) => [
      gameSettingsActions.getNftsFailure(),
      gameSettingsActions.setGameSettingsErrors({ nft: error.message }),
    ])
  );

export const gameSettingsEpics = combineEpics(
  login,
  createGameAccount,
  getGameAccounts,
  getNfts
);
