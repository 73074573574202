import { AllocationPoolData } from 'models';
import Web3 from 'web3';

import { createAction } from '@reduxjs/toolkit';

export const getAllocationPools = createAction<{
  networkId: string;
  connectedAddress: string;
  web3: Web3;
}>('GET_ALLOCATION_POOLS');

export const getAllocationPoolsSuccess = createAction<AllocationPoolData[]>(
  'GET_ALLOCATION_POOLS_SUCCESS'
);

export const getAllocationPoolsFailure = createAction(
  'GET_ALLOCATION_POOLS_FAILURE'
);

export const resetAllocationState = createAction('RESET_ALLOCATION_STATE');
