import { StakingPoolData } from 'models';
import Web3 from 'web3';

import { createAction } from '@reduxjs/toolkit';

export const getStakingPools = createAction<{
  networkId: string;
  connectedAddress: string;
  web3: Web3;
}>('GET_STAKING_POOLS');

export const getStakingPoolsSuccess = createAction<StakingPoolData[]>(
  'GET_STAKING_POOLS_SUCCESS'
);

export const getStakingPoolsFailure = createAction('GET_STAKING_POOLS_FAILURE');

export const resetStakingState = createAction('RESET_STAKING_STATE');
