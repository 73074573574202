import { VestingPoolData } from 'models';
import { getVestingPoolsSuccess, resetVestingState } from 'store/actions';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface VestingState {
  pools: VestingPoolData[];
}

export const initialVestingState: VestingState = {
  pools: null,
};

export const vestingSlice = createSlice<
  VestingState,
  SliceCaseReducers<VestingState>,
  string
>({
  name: 'vesting',
  initialState: initialVestingState,
  reducers: {},
  extraReducers: {
    [getVestingPoolsSuccess.type]: (
      state,
      action: PayloadAction<VestingPoolData[]>
    ) => {
      state.pools = action.payload;
    },
    [resetVestingState.type]: (state) => {
      state = initialVestingState;
    },
  },
});
