import { canAllocate, getContract, skynityConfig } from 'helpers';
import {
  AllocationPoolConfig,
  AllocationPoolData,
  AllocationPoolItemConfig,
  AllocationPoolItemData,
} from 'models';
import { from, Observable } from 'rxjs';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';

export class AllocationService {
  public static getAllocationPools(
    networkId: string,
    connectedAddress: string,
    web3: Web3,
    gasPrice: string
  ): Observable<AllocationPoolData[]> {
    return from(
      Promise.all(
        skynityConfig.allocation?.pools.map(
          async (allocation: AllocationPoolConfig) => {
            const allocationPoolItems: AllocationPoolItemData[] =
              await Promise.all(
                allocation.poolItems.map(
                  async (
                    allocationPoolItem: AllocationPoolItemConfig
                  ): Promise<AllocationPoolItemData> => {
                    const poolItemContract: Contract = getContract(
                      web3,
                      allocationPoolItem.poolItemContractJSON,
                      networkId,
                      gasPrice
                    );
                    const addressCanAllocate = allocationPoolItem.hasWhitelist
                      ? await canAllocate(poolItemContract, connectedAddress)
                      : true;

                    return {
                      poolItemName: allocationPoolItem.poolItemName,
                      poolItemContract,
                      addressCanAllocate,
                      isActive: allocationPoolItem.isActive,
                    };
                  }
                )
              );

            return {
              poolType: allocation.poolType,
              poolName: allocation.poolName,
              poolDescription: allocation.poolDescription,
              poolItems: allocationPoolItems,
              poolSubtype: allocation.poolSubtype,
              poolTokenSymbol: allocation.poolTokenSymbol,
            };
          }
        )
      )
    );
  }
}
