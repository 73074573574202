import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  first,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { StakingService } from 'services/staking.service';
import * as stakingActions from 'store/actions/staking.actions';
import { selectGasPrice } from 'store/selectors';

import { Action } from '@reduxjs/toolkit';

export const getStakingPools = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(stakingActions.getStakingPools.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return StakingService.getStakingPools(
        payload.networkId,
        payload.connectedAddress,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((stakingPools) => [
          stakingActions.getStakingPoolsSuccess(stakingPools),
        ]),
        catchError(() => [stakingActions.getStakingPoolsFailure()])
      );
    })
  );

export const stakingEpics = combineEpics(getStakingPools);
