import { RoutingPaths } from 'helpers';
import React from 'react';
import { Link } from 'react-router-dom';

export const Hero: React.FC = () => (
  <div className="hero">
    <div className="container text-center">
      <div className="hero-wrapper">
        <h1 className="hero-header mb-24">
          Welcome to <div className="font-bold">Skynity App</div>
        </h1>

        <h3 className="hero-description">Get ready to fight in the sky</h3>

        {/* TODO: Update description */}
        {/* <h3 className="hero-description-2">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc varius
          ut ex sit amet tempus. Integer porttitor pellentesque leo eget
          pretium. Aliquam ut dui ligula. Integer volutpat dapibus mi. Aliquam
          eu nulla ut ipsum blandit hendrerit rhoncus vitae nisl. Duis libero
          sem, congue et dapibus nec, ornare at lorem.
        </h3> */}

        <div className="hero-buttons-container">
          <Link to={RoutingPaths.PrivateSale} className="btn btn-primary">
            Private Sale
          </Link>
          <Link to={RoutingPaths.Staking} className="btn btn-primary inactive">
            Stake
          </Link>
        </div>
      </div>
    </div>
  </div>
);
