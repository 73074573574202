import { AllocationPoolSubtype } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBalances, selectStablecoinPrice } from 'store';

import AllocationPools from './AllocationPools';

export const SeedAllocation: React.FC = () => {
  const balances = useSelector(selectBalances);
  const stableTokenPrice = useSelector(selectStablecoinPrice);

  return (
    <section className="staking allocation container">
      <div className="container-inner">
        <div className="section-container">
          <div className="section-header">
            <h3 className="section-header-title">Seed pre-game $SDT access</h3>
            <div className="section-header-description">
              If you are enrolled for early pre-game seed token access (Apply on{' '}
              <a
                href="https://discord.com/invite/skynity"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                discord
              </a>
              ), you will see options below to buy $SDT token at a special price
              of $0.032.
              {balances && stableTokenPrice ? (
                ''
              ) : (
                <div className="mt-16">
                  Connect your wallet to see allocation pools
                </div>
              )}
            </div>
          </div>
        </div>

        {balances && stableTokenPrice ? (
          <div className="section-container">
            <AllocationPools poolsSubtype={AllocationPoolSubtype.Seed} />
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default SeedAllocation;
