import { AllocationPoolSubtype } from 'models';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBalances, selectStablecoinPrice } from 'store';

import AllocationPools from './AllocationPools';

export const PublicAllocation: React.FC = () => {
  const balances = useSelector(selectBalances);
  const stableTokenPrice = useSelector(selectStablecoinPrice);

  return (
    <section className="staking allocation container">
      <div className="container-inner">
        <div className="section-container">
          <div className="section-header">
            <h3 className="section-header-title">Public pre-game $SDT sale</h3>
            <div className="section-header-description">
              <div>
                Welcome to the public pre-game SkyNity SkyDust $SDT token sale,
                open for everyone!
              </div>

              <div className="font-bold mt-8">Duration</div>
              <div>
                22.02, 22:00 UTC - 03.03, 15:00 UTC (or while supply lasts)
              </div>

              <div className="font-bold mt-8">Sale token price</div>
              <div>1 $SDT = $0.05</div>

              <div className="font-bold mt-8">Limits</div>
              <ul className="mb-0">
                <li>Max. allocation per wallet - $222</li>
                <li>Total $SDT value dedicated to this sale - $22,222</li>
              </ul>

              {balances && stableTokenPrice ? (
                ''
              ) : (
                <div className="mt-16">
                  Connect your wallet to see allocation pools
                </div>
              )}
            </div>
          </div>
        </div>

        {balances && stableTokenPrice ? (
          <div className="section-container">
            <AllocationPools poolsSubtype={AllocationPoolSubtype.Public} />
          </div>
        ) : (
          ''
        )}
      </div>
    </section>
  );
};

export default PublicAllocation;
