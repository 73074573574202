// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApiNft {
  name: string; // 'SkyLands #255';
  image: string; // 'ipfs://QmVCExXNwAWu7Z2wx5eSGAckZqho8r2mAZtCMUZ6SaUAKG';
  dna: string; // '0x00000000000000';
  edition: number; // 255: ;
  attributes: ApiNftAttribute[];
  // { trait_type: 'Land Origin'; value: 'Ice' },
  // { trait_type: 'Land Tier'; value: 'Common' },
  // { trait_type: 'Summoning Rift'; value: 'None' },
  // { trait_type: 'Raw SkyDust Mine'; value: 'Level 1' },
  // { trait_type: '$SDT SkyDust Portal'; value: 'None' },
  // { trait_type: 'Mage Tower'; value: 'No' },
  // { trait_type: 'Fortified Wall'; value: 'None' }
  createdBy: string; //  'MintDAO.io';
  description: string; // 'Welcome to SkyLands, a fantastical world of 2,520 floating lands that are your entry ticket into the wondrous realm of SkyNity, where Gods reign supreme and SkyLanders fight for the precious SkyDust. Thanks to their God-given powers, these limited NFTs are both a collectible and a signpost on your journey through the game.';
}

export interface ApiNftAttribute {
  trait_type: NftTraitType; // 'Land Origin'
  value: string; // 'Ice'
}

export enum NftTraitType {
  LandOrigin = 'Land Origin',
  LandTier = 'Land Tier',
  SummoningRift = 'Summoning Rift',
  SkyDustMine = 'Raw SkyDust Mine',
  SkyDustPortal = '$SDT SkyDust Portal',
  MageTower = 'Mage Tower',
  FortifiedWall = 'Fortified Wall',
}

export interface Nft {
  name: string; // 'SkyLands #255';
  image: string; // 'ipfs://QmVCExXNwAWu7Z2wx5eSGAckZqho8r2mAZtCMUZ6SaUAKG';
  dna: string; // '0x00000000000000';
  edition: number; // 255: ;
  attributes: NftAttributes;
  createdBy: string; //  'MintDAO.io';
  description: string; // 'Welcome to SkyLands, a fantastical world of 2,520 floating lands that are your entry ticket into the wondrous realm of SkyNity, where Gods reign supreme and SkyLanders fight for the precious SkyDust. Thanks to their God-given powers, these limited NFTs are both a collectible and a signpost on your journey through the game.';
  rarityScore?: number;
}

export type NftAttributes = Record<
  Uncapitalize<keyof typeof NftTraitType>,
  string
>;

export enum NftRarity {
  Common,
  Uncommon,
  Rare,
  Epic,
  Legendary,
}
