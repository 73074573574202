import { combineEpics } from 'redux-observable';
import { catchError, filter, first, Observable, switchMap } from 'rxjs';
import { StablecoinService } from 'services';
import * as stablecoinActions from 'store/actions/stablecoin.actions';

import { Action } from '@reduxjs/toolkit';

export const getStableTokenPricesEpic = (
  actions$: Observable<Action>
): Observable<Action> =>
  actions$.pipe(
    filter(stablecoinActions.getStablecoinPrices.match),
    switchMap(() =>
      StablecoinService.getStablecoinPrices().pipe(
        first(),
        switchMap((payload) => [
          stablecoinActions.getStablecoinPricesSuccess(payload),
        ]),
        catchError(() => [stablecoinActions.getStablecoinPricesFailure()])
      )
    )
  );

export const stablecoinEpics = combineEpics(getStableTokenPricesEpic);
