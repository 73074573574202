import skynityLogo from 'assets/svg/skynity-logo.svg';
import React, { ReactElement } from 'react';

export const Preloader = (): ReactElement => (
  <div className="preloader">
    <div className="preloader-container">
      <div className="preloader-image">
        <img src={skynityLogo} alt="Skynity loader" />
      </div>
    </div>
  </div>
);

export default Preloader;
