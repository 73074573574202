/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import {
  AllocationPoolItemContent,
  AllocationPoolItemDetails,
  AllocationPoolItemHeader,
  Message,
} from 'components';
import {
  getAllocationPoolItemData,
  isCorrectValue,
  MAX_UINT256,
  MessageText,
  MessageType,
  postAllocate,
} from 'helpers';
import {
  AllocationPoolItemAllocationData,
  AllocationPoolItemData,
  MainTokenSymbol,
  TokenData,
  TokensData,
} from 'models';
import React, { useEffect, useReducer } from 'react';
import { Accordion, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getBalances, selectConnectedAddress } from 'store';

import {
  AllocationPoolDetailsActions,
  allocationPoolDetailsReducer,
  initialAllocationPoolDetailsState,
} from '../store/reducer';

interface AllocationPoolItemProps {
  index: number;
  balance: string;
  allocationPoolItemData: AllocationPoolItemData;
  allocationTokenData: TokenData;
  tokensData: TokensData;
  active: boolean;
}

export const AllocationPoolItem: React.FC<AllocationPoolItemProps> = ({
  index,
  balance,
  allocationPoolItemData,
  allocationTokenData,
  tokensData,
  active,
}) => {
  const [state, dispatch] = useReducer(
    allocationPoolDetailsReducer,
    initialAllocationPoolDetailsState
  );
  const dispatchApp = useDispatch();
  const connectedAddress: string = useSelector(selectConnectedAddress);

  const getData = (): void => {
    getAllocationPoolDetailsData();
    dispatchApp(
      getBalances({
        tokensData: [tokensData.SDT, tokensData.USDT, tokensData.BUSD],
        connectedAddress,
      })
    );
  };

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   if (state.allocation?.totalSupply) {
  //     setAllocationTvl();
  //   }
  // }, [state.allocation]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
    if (state.loading === false) {
      getData();
    }
  }, [state.loading]);

  // TODO: TEMPORARY FUNCTION, FIND BETTER APPROACH
  // const setAllocationTvl = () => {
  //   dispatchApp(
  //     appActions.setAllocationTvl({
  //       symbol: allocationTokenData.symbol,
  //       totalSupply: state.allocation.totalSupply,
  //     })
  //   );
  // };

  const getAllocationPoolDetailsData = async (): Promise<void> => {
    try {
      const allocationPoolItemAllocationData: AllocationPoolItemAllocationData =
        await getAllocationPoolItemData(
          allocationPoolItemData,
          allocationTokenData,
          connectedAddress
        );

      dispatch({
        type: AllocationPoolDetailsActions.SetAllocation,
        payload: allocationPoolItemAllocationData,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const onApprove = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();

    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: true });
    dispatch({
      type: AllocationPoolDetailsActions.SetErrorMessage,
      payload: '',
    });

    try {
      await allocationTokenData.tokenContract.methods
        .approve(
          allocationPoolItemData.poolItemContract.options.address,
          MAX_UINT256
        )
        .send({ from: connectedAddress });

      // TODO: Find better way for refresh
      dispatch({
        type: AllocationPoolDetailsActions.SetAllocationValue,
        payload: '',
      });
    } catch (error) {
      dispatch({
        type: AllocationPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }
    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: false });
  };

  const onAllocate = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault();
    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: true });
    dispatch({
      type: AllocationPoolDetailsActions.SetErrorMessage,
      payload: '',
    });

    try {
      await postAllocate(
        allocationPoolItemData,
        state.allocationValue,
        allocationTokenData.decimals,
        connectedAddress
      );
    } catch (error) {
      dispatch({
        type: AllocationPoolDetailsActions.SetErrorMessage,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        payload: (error as any).message,
      });
    }

    dispatch({ type: AllocationPoolDetailsActions.SetLoading, payload: false });
    dispatch({
      type: AllocationPoolDetailsActions.SetAllocationValue,
      payload: '',
    });
  };

  const onAllocateValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value;

    if (isCorrectValue(value, allocationTokenData.decimals)) {
      dispatch({
        type: AllocationPoolDetailsActions.SetAllocationValue,
        payload: value,
      });
    }
  };

  const onApplyPercentage = (percentage: number, value: string): void => {
    const BNValue = new BigNumber(value);
    const roundedNumber = BNValue.times(percentage)
      .div(100)
      .toFixed(allocationTokenData.decimals);

    dispatch({
      type: AllocationPoolDetailsActions.SetAllocationValue,
      payload: roundedNumber,
    });
  };

  const stakingPoolItemClassNames = classNames(
    'staking-pool-item',
    'allocation-pool-item',
    { inactive: !active }
  );

  const showBody =
    active ||
    (allocationPoolItemData.addressCanAllocate &&
      state.allocation?.allocationAllowance &&
      new BigNumber(state.allocation?.allocatedTokens).isGreaterThan(0));
  const showDetails =
    state.allocation?.allocationAllowance &&
    ((active &&
      new BigNumber(state.allocation?.allocationAllowance).isGreaterThan(0)) ||
      (!active &&
        new BigNumber(state.allocation?.allocatedTokens).isGreaterThan(0)));

  return (
    <Accordion className={stakingPoolItemClassNames} defaultActiveKey="0">
      <Accordion.Item
        eventKey={index.toString()}
        className={classNames({ loading: !state.allocation })}
      >
        <Accordion.Header>
          <AllocationPoolItemHeader
            allocationPoolItemData={allocationPoolItemData}
            allocation={state.allocation}
            decimals={allocationTokenData.decimals}
          />
        </Accordion.Header>

        {showBody && state.allocation && (
          <Accordion.Body>
            <div className="staking-pool-item-body">
              {showDetails ? (
                <AllocationPoolItemDetails
                  balance={balance}
                  allocation={state.allocation}
                  symbol={allocationTokenData.symbol as MainTokenSymbol}
                />
              ) : (
                ''
              )}
              {active ? (
                <Tab.Container id="tabs-container" defaultActiveKey="allocate">
                  <Tab.Content className="tabs">
                    <AllocationPoolItemContent
                      loading={state.loading}
                      balance={balance}
                      allocationValue={state.allocationValue}
                      allocation={state.allocation}
                      allocationTokenData={allocationTokenData}
                      onAllocate={(event): Promise<void> => onAllocate(event)}
                      onApprove={(event): Promise<void> => onApprove(event)}
                      onAllocateValueChange={(event): void =>
                        onAllocateValueChange(event)
                      }
                      onApplyPercentage={(percentage, value): void =>
                        onApplyPercentage(percentage, value)
                      }
                    />
                    {state.errorMessage && (
                      <Message
                        descriptionText={
                          state.errorMessage.includes('404')
                            ? `${state.errorMessage} ${MessageText.Fees}`
                            : state.errorMessage
                        }
                        messageType={MessageType.Error}
                      />
                    )}
                  </Tab.Content>
                </Tab.Container>
              ) : (
                ''
              )}
            </div>
          </Accordion.Body>
        )}
      </Accordion.Item>
    </Accordion>
  );
};
