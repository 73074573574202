import { AllocationPoolItemAllocationData } from 'models';

import { PayloadAction } from '@reduxjs/toolkit';

export interface AllocationPoolDetailsState {
  loading: boolean;
  errorMessage: string;
  allocationValue: string;
  allocation: AllocationPoolItemAllocationData;
  isExpanded: boolean;
}

export enum AllocationPoolDetailsActions {
  SetLoading = 'SET_LOADING',
  SetErrorMessage = 'SET_ERROR_MESSAGE',
  SetAllocationValue = 'SET_STAKING_VALUE',
  SetAllocation = 'SET_ALLOCATION',
  SetIsExpanded = 'SET_IS_EXPANDED',
}

export const initialAllocationPoolDetailsState: AllocationPoolDetailsState = {
  loading: null,
  errorMessage: '',
  allocationValue: '',
  allocation: null,
  isExpanded: true,
};

export const allocationPoolDetailsReducer = (
  state: AllocationPoolDetailsState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: PayloadAction<any, AllocationPoolDetailsActions>
): AllocationPoolDetailsState => {
  switch (action.type) {
    case AllocationPoolDetailsActions.SetLoading: {
      return { ...state, loading: action.payload };
    }
    case AllocationPoolDetailsActions.SetErrorMessage: {
      return { ...state, errorMessage: action.payload };
    }
    case AllocationPoolDetailsActions.SetAllocationValue: {
      return { ...state, allocationValue: action.payload };
    }
    case AllocationPoolDetailsActions.SetAllocation: {
      return {
        ...state,
        allocation: { ...action.payload },
      };
    }
    case AllocationPoolDetailsActions.SetIsExpanded: {
      return {
        ...state,
        isExpanded: action.payload,
      };
    }
    default: {
      state;
    }
  }
};
