import { LPPairsContracts, LPsData, TokenPrices } from 'models';
import { State } from 'store';
import { LPState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectLP = (state: State): LPState => state.lp;

export const selectLpPairsContracts = createSelector(
  [selectLP],
  (lpState: LPState): LPPairsContracts => lpState?.lpPairsContracts
);

export const selectLPTokenPrices = createSelector(
  [selectLP],
  (state: LPState): TokenPrices => state.LPTokensPrices
);

export const selectLPsData = createSelector(
  [selectLP],
  (state: LPState): LPsData => state.lpsData
);
