import { AppChain, CGStableCoins } from 'helpers';
import { StableTokenPrices } from 'models';
import { State } from 'store';
import { StablecoinState } from 'store/reducers';

import { createSelector } from '@reduxjs/toolkit';

const selectStablecoin = (state: State): StablecoinState => state.stablecoin;

export const selectStablecoinPrices = createSelector(
  [selectStablecoin],
  (state: StablecoinState): StableTokenPrices => state.stablecoinPrices
);

export const selectStablecoinPrice = createSelector(
  [selectStablecoinPrices],
  (stableTokenPrices: StableTokenPrices): string => {
    switch (process.env.REACT_APP_CHAIN) {
      case AppChain.Bsc: {
        return stableTokenPrices && stableTokenPrices[CGStableCoins.USDT];
      }

      case AppChain.Polygon: {
        return stableTokenPrices && stableTokenPrices[CGStableCoins.DAI];
      }

      default: {
        throw new Error('Unsupported Chain');
      }
    }
  }
);
