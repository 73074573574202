import { AllocationPoolData } from 'models';
import {
  getAllocationPoolsSuccess,
  resetAllocationState,
} from 'store/actions/allocation.actions';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface AllocationState {
  pools: AllocationPoolData[];
}

export const initialAllocationState: AllocationState = {
  pools: null,
};

export const allocationSlice = createSlice<
  AllocationState,
  SliceCaseReducers<AllocationState>,
  string
>({
  name: 'allocation',
  initialState: initialAllocationState,
  reducers: {},
  extraReducers: {
    [getAllocationPoolsSuccess.type]: (
      state,
      action: PayloadAction<AllocationPoolData[]>
    ) => {
      state.pools = action.payload;
    },
    [resetAllocationState.type]: (state) => {
      state = initialAllocationState;
    },
  },
});
