import skynityLogoGraph from 'assets/svg/skynity-logo-graphic.svg';
import skynityLogo from 'assets/svg/skynity-logo-small.svg';
import React, { ReactElement } from 'react';

export const Footer = (): ReactElement => (
  <footer className="footer">
    <div className="container text-center">
      <img className="footer-logo" src={skynityLogoGraph} alt="Skynity logo" />

      {/* Links */}
      <div className="footer-links">
        <a
          className="link footer-link"
          href="https://www.skynity.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Skynity.io
        </a>
        <a
          className="link footer-link"
          href="https://skynity.io/uploads/SkyNity_WhitePaper.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Whitepaper
        </a>
        <a
          className="link footer-link"
          href="https://medium.com/@SkyNity"
          target="_blank"
          rel="noopener noreferrer"
        >
          Medium
        </a>
      </div>

      <div className="footer-socials">
        <a
          className="link"
          href="https://www.facebook.com/SkyNity.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-facebook footer-social" />
        </a>
        <a
          className="link"
          href="https://t.me/skynity_io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-telegram footer-social"></i>
        </a>
        <a
          className="link"
          href="https://twitter.com/SkyNity_io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-twitter footer-social" />
        </a>
        <a
          className="link"
          href="https://discord.gg/skynity"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-discord footer-social" />
        </a>
      </div>

      <div className="footer-links">
        <div className="footer-link font-small">
          Copyright &#169; {new Date().getFullYear()} Skynity
        </div>

        <div className="footer-link font-small">
          Authorised by the Estonia FS under registry code 16372654
        </div>
      </div>

      <img src={skynityLogo} alt="Skynity logo" />
    </div>
  </footer>
);
