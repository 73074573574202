import { bscConfig } from 'config';
import { AppConfig } from 'models';

export enum AppChain {
  Bsc = 'BSC',
  Polygon = 'Polygon',
}

export enum AppChainGroup {
  Evm = 'EVM',
}

export const evmChains: AppChain[] = [AppChain.Bsc, AppChain.Polygon];

// TODO: Use isChain - circular dependecy - separate MR
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const skynityConfig: AppConfig = ((): any => {
  switch (process.env.REACT_APP_CHAIN) {
    case AppChain.Bsc: {
      return bscConfig;
    }
  }
})();
