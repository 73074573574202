import { TokenBalances, TokenPrices, TokensData } from 'models';
import { State, TokenState } from 'store';
import { Contract } from 'web3-eth-contract';

import { createSelector } from '@reduxjs/toolkit';

const selectToken = (state: State): TokenState => state.token;

export const selectBalances = createSelector(
  [selectToken],
  (state: TokenState): TokenBalances => state.tokensBalances
);

export const selectTokensData = createSelector(
  [selectToken],
  (state: TokenState): TokensData => state.tokensData
);

export const selectTokensPrices = createSelector(
  [selectToken],
  (state: TokenState): TokenPrices => state.tokensPrices
);

export const selectNftContract = createSelector(
  [selectToken],
  (state: TokenState): Contract => state.nftTokenContract
);

export const selectGameBridgeContract = createSelector(
  [selectToken],
  (state: TokenState): Contract => state.gameBridgeContract
);
