import { StakingPoolItemStakingData } from 'models';

import { PayloadAction } from '@reduxjs/toolkit';

export interface StakingPoolDetailsState {
  loading: boolean;
  errorMessage: string;
  unstakingValue: string;
  stakingValue: string;
  staking: StakingPoolItemStakingData;
  isExpanded: boolean;
}

export enum StakingPoolDetailsActions {
  SetLoading = 'SET_LOADING',
  SetErrorMessage = 'SET_ERROR_MESSAGE',
  SetStakingValue = 'SET_STAKING_VALUE',
  SetUnstakingValue = 'SET_UNSTAKING_VALUE',
  SetStaking = 'SET_STAKING',
  SetIsExpanded = 'SET_IS_EXPANDED',
}

export const initialStakingPoolDetailsState: StakingPoolDetailsState = {
  loading: null,
  errorMessage: '',
  unstakingValue: '',
  stakingValue: '',
  staking: null,
  isExpanded: null,
};

export const stakingPoolDetailsReducer = (
  state: StakingPoolDetailsState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: PayloadAction<any, StakingPoolDetailsActions>
): StakingPoolDetailsState => {
  switch (action.type) {
    case StakingPoolDetailsActions.SetLoading: {
      return { ...state, loading: action.payload };
    }
    case StakingPoolDetailsActions.SetErrorMessage: {
      return { ...state, errorMessage: action.payload };
    }
    case StakingPoolDetailsActions.SetStakingValue: {
      return { ...state, stakingValue: action.payload };
    }
    case StakingPoolDetailsActions.SetUnstakingValue: {
      return { ...state, unstakingValue: action.payload };
    }
    case StakingPoolDetailsActions.SetStaking: {
      return {
        ...state,
        staking: { ...action.payload },
      };
    }
    case StakingPoolDetailsActions.SetIsExpanded: {
      return {
        ...state,
        isExpanded: action.payload,
      };
    }
    default: {
      state;
    }
  }
};
