/* eslint-disable complexity */
import BigNumber from 'bignumber.js';
import { ButtonWithSpinner, Message, PercentageButtons } from 'components';
import { MessageText, MessageType, numbersOnly } from 'helpers';
import { AllocationPoolItemAllocationData, TokenData } from 'models';
import React from 'react';
import { Tab } from 'react-bootstrap';

interface AllocationPooItemContentProps {
  loading: boolean;
  balance: string;
  allocationValue: string;
  allocation: AllocationPoolItemAllocationData;
  allocationTokenData: TokenData;
  onApprove: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAllocate: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onAllocateValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onApplyPercentage: (percentage: number, value: string) => void;
}

export const AllocationPoolItemContent: React.FC<
  AllocationPooItemContentProps
> = ({
  loading,
  balance,
  allocationValue,
  allocation,
  allocationTokenData,
  onApprove,
  onAllocate,
  onAllocateValueChange,
  onApplyPercentage,
}) => {
  const totalSupplyBN = new BigNumber(allocation.totalSupply);
  const maxTotalSupplyBN = new BigNumber(allocation.maxTotalSupply);
  const allocationValueBN = new BigNumber(allocationValue);
  const balanceBN = new BigNumber(balance);
  const allocationAllowanceBN = new BigNumber(allocation.allocationAllowance);
  const allocationLeftBN = new BigNumber(allocation.allocationLeft);
  const maxAllocationReached =
    allocationValueBN.isGreaterThan(allocationLeftBN);
  const totalSupplyLeftBN = maxTotalSupplyBN.minus(totalSupplyBN);
  const maxTotalSupplyReached =
    maxTotalSupplyBN.isGreaterThan(0) &&
    allocationValueBN.isGreaterThan(totalSupplyLeftBN);
  const allocateButtonDisabled =
    +allocationValue == 0 ||
    allocationValueBN.isGreaterThan(balanceBN) ||
    maxAllocationReached ||
    maxTotalSupplyReached;

  const button = allocationValueBN.isGreaterThan(allocationAllowanceBN) ? (
    <ButtonWithSpinner
      text="Approve"
      classes="btn btn-primary approve-section-button section-action-button"
      onClick={onApprove}
      loading={loading}
    />
  ) : (
    <ButtonWithSpinner
      text="Deposit"
      classes="btn btn-primary section-action-button"
      onClick={onAllocate}
      disabled={allocateButtonDisabled}
      loading={loading}
    />
  );

  return (
    <Tab.Pane eventKey="allocate" unmountOnExit={true} mountOnEnter={true}>
      <div className="tab-content">
        {+allocation.allocationAllowance == 0 && (
          <div className="approve-section">
            <div className="mb-16 text-center">
              To participate in sale you need to approve your{' '}
              {allocationTokenData.symbol} tokens first
            </div>
            <ButtonWithSpinner
              text="Approve"
              classes="btn btn-primary approve-section-button section-action-button"
              onClick={onApprove}
              disabled={loading}
              loading={loading}
            />
          </div>
        )}
        {+allocation.allocationAllowance != 0 && (
          <div className="stake-section">
            <div className="stake-section-actions">
              <input
                className="s-input"
                placeholder="0.00"
                onChange={onAllocateValueChange}
                value={allocationValue}
                onKeyPress={numbersOnly}
                disabled={balanceBN.isEqualTo(0)}
              />

              <PercentageButtons
                applyPercentage={(percentage): void =>
                  onApplyPercentage(percentage, allocation.allocationLeft)
                }
                disabled={balanceBN.isEqualTo(0) || loading}
              />

              {button}
            </div>
          </div>
        )}
        {allocationValueBN.isGreaterThan(allocationAllowanceBN) ? (
          <Message
            messageType={MessageType.Error}
            descriptionText={MessageText.ValueGreaterThanApproved}
          />
        ) : (
          ''
        )}
      </div>
      {!+balance && !!+allocation.allocationAllowance && (
        <Message
          descriptionText={MessageText.NoTokens}
          messageType={MessageType.Primary}
        />
      )}
      {maxAllocationReached ? (
        <Message
          descriptionText={`Entered value will exceed your allocation limit of ${allocation.allocationLimit} ${allocationTokenData.symbol}`}
          messageType={MessageType.Primary}
        />
      ) : (
        ''
      )}
      {maxTotalSupplyReached && !maxAllocationReached ? (
        <Message
          descriptionText={`Entered value will exceed total pool limit of ${allocation.maxTotalSupply} ${allocationTokenData.symbol}`}
          messageType={MessageType.Primary}
        />
      ) : (
        ''
      )}
    </Tab.Pane>
  );
};
