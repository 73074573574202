import Web3 from 'web3';
import Web3Modal from 'web3modal';

import { createAction } from '@reduxjs/toolkit';

export const setWeb3 = createAction<Web3>('SET_WEB_3');

export const setWeb3Modal = createAction<Web3Modal>('SET_WEB_3_MODAL');

export const setWeb3Provider = createAction<unknown>('SET_WEB_3_PROVIDER');

export const setInitialData = createAction<{
  web3: Web3;
  gasPrice: string;
  connectedAddress: string;
}>('SET_INITIAL_DATA');

export const setShowLoader = createAction<boolean>('SET_SHOW_LOADER');

export const setInitializingFinished = createAction<boolean>(
  'SET_INITIALIZING_FINISHED'
);

export const setConnectedAddress = createAction<string>(
  'SET_CONNECTED_ADDRESS'
);

export const resetAppState = createAction('RESET_APP_STATE');
