import { InfoCard } from 'components';
import { AppChain, contractsAvailable, getChainName } from 'helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectConnectedAddress, selectTokensData } from 'store';

export const Placeholder: React.FC = () => {
  const connectedAddress = useSelector(selectConnectedAddress);
  const tokensData = useSelector(selectTokensData);

  if (connectedAddress) {
    if (!contractsAvailable(tokensData)) {
      const wrongNetworkText = `Please change network to ${getChainName(
        process.env.REACT_APP_CHAIN as AppChain
      )} Mainnet to continue your journey`;

      return (
        <InfoCard
          headerText="Wrong network selected"
          description={wrongNetworkText}
        ></InfoCard>
      );
    }
  } else {
    return (
      <InfoCard
        headerText="Connect Wallet"
        description="Get access to all functionalities of our dApp."
      ></InfoCard>
    );
  }
};
