import { GameLauncher, Hero } from 'components';
import React, { ReactElement } from 'react';

export const Home = (): ReactElement => (
  <div className="home">
    <Hero />

    <GameLauncher />
  </div>
);
