import { LPsData, LPTokensPrices } from 'models';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  first,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { ContractService } from 'services';
import * as lpActions from 'store/actions/lp.actions';
import { selectGasPrice } from 'store/selectors';

import { Action } from '@reduxjs/toolkit';

export const getLPPairsContracts = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(lpActions.getLPPairsContracts.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return ContractService.getLPPairsContracts(
        payload.networkId,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((lpPairsContracts) => [
          lpActions.getLPPairsContractsSuccess(lpPairsContracts),
        ]),
        catchError(() => [lpActions.getLPPairsContractsFailure()])
      );
    })
  );

export const getLPTokensPricesEpic = (
  actions$: Observable<Action>
): Observable<Action> =>
  actions$.pipe(
    filter(lpActions.getLPTokensPrices.match),
    switchMap(({ payload }) =>
      ContractService.getLPTokensPrices(
        payload.tokensPrices,
        payload.LPsData
      ).pipe(
        first(),
        switchMap((payload: LPTokensPrices) => [
          lpActions.getLPTokensPricesSuccess(payload),
        ]),
        catchError(() => [lpActions.getLPTokensPricesFailure()])
      )
    )
  );

export const getLPsData = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(lpActions.getLPsData.match),
    switchMap(({ payload }) =>
      ContractService.getLPsData(
        payload.tokensData,
        payload.LPPairsContracts
      ).pipe(
        first(),
        switchMap((payload: LPsData) => [lpActions.getLPsDataSuccess(payload)]),
        catchError(() => [lpActions.getLPDataFailure()])
      )
    )
  );

export const lpEpics = combineEpics(
  getLPPairsContracts,
  getLPTokensPricesEpic,
  getLPsData
);
