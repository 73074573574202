import { StableTokenPrices } from 'models';
import {
  getStablecoinPricesSuccess,
  resetStablecoinState,
} from 'store/actions';

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

export interface StablecoinState {
  stablecoinPrices: StableTokenPrices;
}

export const initialStablecoinState: StablecoinState = {
  stablecoinPrices: null,
};

export const stablecoinSlice = createSlice<
  StablecoinState,
  SliceCaseReducers<StablecoinState>,
  string
>({
  name: 'stablecoin',
  initialState: initialStablecoinState,
  reducers: {},
  extraReducers: {
    [getStablecoinPricesSuccess.type]: (state, action) => {
      state.stablecoinPrices = action.payload;
    },
    [resetStablecoinState.type]: (state) => {
      state = initialStablecoinState;
    },
  },
});
