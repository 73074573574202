import React, { PropsWithChildren } from 'react';

interface SectionHeaderProps {
  title: string;
  description?: string;
}

export const SectionHeader: React.FC<PropsWithChildren<SectionHeaderProps>> = ({
  title,
  description,
  children,
}) => (
  <div className="section-header">
    <h3 className="section-header-title">{title}</h3>
    <div className="section-header-description">{description || children}</div>
  </div>
);
