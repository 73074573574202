import { VestingPoolData } from 'models';
import Web3 from 'web3';

import { createAction } from '@reduxjs/toolkit';

export const getVestingPools = createAction<{
  networkId: string;
  connectedAddress: string;
  web3: Web3;
}>('GET_VESTING_POOLS');

export const getVestingPoolsSuccess = createAction<VestingPoolData[]>(
  'GET_VESTING_POOLS_SUCCESS'
);

export const getVestingPoolsFailure = createAction('GET_VESTING_POOLS_FAILURE');

export const resetVestingState = createAction('RESET_VESTING_STATE');
