export const enum RoutingPaths {
  Home = '/',
  Staking = '/staking',
  Vesting = '/vesting',
  Settings = '/settings',
  Marketplace = '/marketplace',
  PrivateSale = '/private',
  PublicSale = '/public',
  SeedSale = '/seed',
}
