import { BscNetworkType } from 'config';
import { AppChain, skynityConfig } from 'helpers';

import { AppChainGroup, evmChains } from './config.helper';

export enum NoConnectionType {
  WrongNetwork = 'wrong-network',
  WalletNotConnected = 'wallet-not-connected',
}

export const isPermittedNetwork = (networkId: string): boolean =>
  skynityConfig.permittedNetworks.includes(networkId as BscNetworkType);

export const isChain = (appChain: AppChain | AppChainGroup): boolean => {
  const isEvmChain = evmChains.includes(
    process.env.REACT_APP_CHAIN as AppChain
  );

  return appChain === AppChainGroup.Evm
    ? isEvmChain
    : process.env.REACT_APP_CHAIN === appChain;
};

export const getChainName = (appChain: AppChain): string => {
  switch (appChain) {
    case AppChain.Bsc: {
      return 'Binance Smart Chain';
    }

    case AppChain.Polygon: {
      return 'Polygon';
    }

    default: {
      throw new Error('Unsupported Chain');
    }
  }
};
