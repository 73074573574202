import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  first,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { VestingService } from 'services';
import { selectGasPrice } from 'store';
import * as vestingActions from 'store/actions/vesting.actions';

import { Action } from '@reduxjs/toolkit';

export const getVestingPools = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(vestingActions.getVestingPools.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return VestingService.getVestingPools(
        payload.networkId,
        payload.connectedAddress,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((vestingPools) => [
          vestingActions.getVestingPoolsSuccess(vestingPools),
        ]),
        catchError(() => [vestingActions.getVestingPoolsFailure()])
      );
    })
  );

export const vestingEpics = combineEpics(getVestingPools);
