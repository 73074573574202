import {
  resetAppState,
  setConnectedAddress,
  setInitialData,
  setInitializingFinished,
  setShowLoader,
  setWeb3,
  setWeb3Modal,
  setWeb3Provider,
} from 'store/actions';
import Web3 from 'web3';
import Web3Modal from 'web3modal';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface AppState {
  web3: Web3;
  web3Modal: Web3Modal;
  web3Provider: unknown;
  gasPrice: string;
  connectedAddress: string;
  initializingFinished: boolean;
  showLoader: boolean;
}

export const initialAppState: AppState = {
  web3: null,
  web3Modal: null,
  web3Provider: null,
  gasPrice: null,
  connectedAddress: null,
  initializingFinished: null,
  showLoader: true,
};

export const appSlice = createSlice<
  AppState,
  SliceCaseReducers<AppState>,
  string
>({
  name: 'app',
  initialState: initialAppState,
  reducers: {},
  extraReducers: {
    [setWeb3.type]: (state, action: PayloadAction<Web3>) => {
      state.web3 = action.payload;
    },
    [setWeb3Modal.type]: (state, action: PayloadAction<Web3Modal>) => {
      state.web3Modal = action.payload;
    },
    [setWeb3Provider.type]: (state, action: PayloadAction<unknown>) => {
      state.web3Provider = action.payload;
    },
    [setConnectedAddress.type]: (state, action: PayloadAction<string>) => {
      state.connectedAddress = action.payload;
    },
    [setInitializingFinished.type]: (state, action: PayloadAction<boolean>) => {
      state.initializingFinished = action.payload;
    },
    [setInitialData.type]: (state, action: PayloadAction<AppState>) => {
      state.web3 = action.payload.web3;
      state.gasPrice = action.payload.gasPrice;
      state.connectedAddress = action.payload.connectedAddress;
    },
    [setShowLoader.type]: (state, action) => {
      state.showLoader = action.payload;
    },

    [resetAppState.type]: (state) => {
      state = initialAppState;
    },
  },
});
