import { MessageType } from 'helpers';
import React, { PropsWithChildren } from 'react';

export const Message: React.FC<
  PropsWithChildren<{
    messageType?: MessageType;
    descriptionText?: string;
    headerText?: string;
  }>
> = ({ descriptionText, messageType, headerText, children }) => (
  <div className={`message ${messageType}`}>
    {headerText && <div className="message-header">{headerText}</div>}
    <div className={`message-description`}>
      {descriptionText} <span>{children}</span>
    </div>
  </div>
);
