/* eslint-disable sonarjs/no-duplicate-string */
import { SkynityCookie } from 'helpers';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import {
  CreateGameAccountPayload,
  CreateGameAccountResponse,
  GameAccount,
  LoginResponse,
} from 'models';
import { from, map, mergeMap, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import Web3 from 'web3';

export class GameSettingsService {
  public static login = (
    address: string,
    web3: Web3
  ): Observable<LoginResponse> => {
    const getToken = async (): Promise<LoginResponse> => {
      try {
        const data = {
          timestamp: Date.now(),
        };
        const signature = await web3.eth.personal.sign(
          JSON.stringify(data),
          address,
          null
        );
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/login`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data,
              address,
              signature,
            }),
          }
        );
        const responseJson = await response.json();

        if (response.ok) {
          const authToken = (responseJson as LoginResponse).auth_token;
          const decodedToken = jwtDecode(authToken);
          const expirationTime = new Date(decodedToken.exp * 1000);

          Cookies.set(SkynityCookie.AuthToken, authToken, {
            expires: expirationTime,
          });

          return responseJson as LoginResponse;
        } else {
          throw new Error(responseJson.message);
        }
      } catch (error: any) {
        throw new Error(error.message);
      }
    };

    return from(getToken());
  };

  public static getGameAccounts(authToken: string): Observable<GameAccount[]> {
    return ajax<{ gameAccounts: GameAccount[] }>({
      url: `${process.env.REACT_APP_API_URL}/accounts`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    }).pipe(map((response) => response.response.gameAccounts));
  }

  public static changeGameAccountPassword = (
    address: string,
    web3: Web3,
    hid: string,
    password: string,
    authToken: string
  ): Promise<any> => {
    const changePassword = async (): Promise<any> => {
      const data = {
        account: {
          hid,
          password,
        },
      };

      const signature = await web3.eth.personal.sign(
        JSON.stringify(data),
        address,
        null
      );
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/account/password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            data,
            address,
            signature,
          }),
        }
      );
      const responseJson = await response.json();

      if (response.ok) {
        return responseJson;
      } else {
        throw new Error(responseJson.message);
      }
    };

    return changePassword();
  };

  public static createGameAccount = (
    address: string,
    web3: Web3,
    authToken: string,
    createGameAccountPayload: CreateGameAccountPayload
  ): Observable<CreateGameAccountResponse> => {
    const data = {
      account: {
        name: createGameAccountPayload.accountName,
        password: createGameAccountPayload.accountPassword,
        nft_id: createGameAccountPayload.nftId,
      },
    };
    const signature: Promise<string> = web3.eth.personal.sign(
      JSON.stringify(data),
      address,
      null
    );

    return from(signature).pipe(
      mergeMap((signature: string) => {
        const body = {
          data,
          address: address,
          signature,
        };

        return ajax<CreateGameAccountResponse>({
          url: `${process.env.REACT_APP_API_URL}/account/create`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(body),
        }).pipe(map((response) => response.response));
      })
    );
  };
}
