import { StableTokenPrices } from 'models';

import { createAction } from '@reduxjs/toolkit';

export const getStablecoinPrices = createAction('GET_STABLE_TOKEN_PRICES');

export const getStablecoinPricesSuccess = createAction<StableTokenPrices>(
  'GET_STABLE_TOKEN_PRICES_SUCCESS'
);

export const getStablecoinPricesFailure = createAction(
  'GET_STABLE_TOKEN_PRICES_FAILURE'
);

export const resetStablecoinState = createAction('RESET_STABLETOKEN_STATE');
