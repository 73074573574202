import { DataItem } from 'components';
import { AllocationPoolItemAllocationData, MainTokenSymbol } from 'models';
import React from 'react';

interface AllocationPoolItemProps {
  balance: string;
  allocation: AllocationPoolItemAllocationData;
  symbol: MainTokenSymbol;
}

export const AllocationPoolItemDetails: React.FC<AllocationPoolItemProps> = ({
  balance,
  allocation,
  symbol,
}) => (
  <div className="staking-pool-item-details">
    <DataItem label="In wallet" data={balance} symbol={symbol} />
    <DataItem
      label="Deposited"
      data={allocation.allocatedTokens}
      symbol={symbol}
    />
    <DataItem
      label="Max. allocation"
      data={allocation.allocationLimit}
      symbol={symbol}
    />
    {/* {!isProduction() ? (
      <DataItem
        label="Allocation left [DEV]"
        data={allocation.allocationLeft}
        symbol={symbol}
      />
    ) : (
      ''
    )}
    {!isProduction() ? (
      <DataItem
        label="Total Supply [DEV]"
        data={allocation.totalSupply}
        symbol={symbol}
      />
    ) : (
      ''
    )} */}
  </div>
);
