/* eslint-disable @typescript-eslint/no-explicit-any */
import BigNumber from 'bignumber.js';
import allocationContractJSON from 'config/bsc/contracts/allocation/SkynityAllocationVaultBUSD.json';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

type PresalePool = {
  name: string;
  address?: string;
  fromAPI?: string;
  decimals?: number;
  price?: number;
  disabled?: boolean;
};

const presalePools: PresalePool[] = [
  {
    name: 'PEX 1 PLY',
    address: '0x96929Aad81bF1b9Aa316fAef251fBC4F40450B77',
    decimals: 6,
    price: 2,
  },
  {
    name: 'PEX 1 BUSD',
    address: '0x9e75003062F88CB2FA7c33DB26e427E55b2C9010',
    decimals: 18,
    price: 0.036,
  },
  {
    name: 'Private BUSD',
    address: '0xD53c7fC65f5ffd635C1340fDdBe2B775DbD62225',
    decimals: 18,
    price: 0.04,
  },
  {
    name: 'PEX 2 BUSD',
    address: '0x036aE88E3a55153FACF0A5D68119F69d90ba681F',
    decimals: 18,
    price: 0.038,
  },
  {
    name: 'Public BUSD',
    address: '0x51bb6CBcEcB4d2f24D4D7197f6ceC479abb35125',
    decimals: 18,
    price: 0.05,
  },
  {
    name: 'Public PLY',
    address: '0x8d2c00796B0A7CFeB1Da722328C48072ec2C99Cd',
    decimals: 6,
    price: 8,
  },
  {
    name: 'Private USDT',
    address: '0x6B57f08fDcA61042002F85b80A66c74b5f0d4f03',
    decimals: 18,
    price: 0.04,
  },
  {
    name: 'Seed USDT',
    address: '0x2a65F8D1F0A5D81Bb2bCb70DFe6F6166cf79Ca5c',
    decimals: 18,
    price: 0.032,
  },
  {
    name: 'Manual',
    fromAPI: 'https://api.playnity.io/api/sdt-presale/manual-alloc',
    // disabled: true,
  },
];

const getAcquiredSDT = async (
  web3: Web3,
  connectedAddress: string,
  presalePool: PresalePool
): Promise<BigNumber> => {
  if (presalePool.address) {
    const contract = new web3.eth.Contract(
      allocationContractJSON.abi as AbiItem[],
      presalePool.address
    );
    const addressStats: {
      totalSupply: string;
      allocationLimit: string;
      depositedAmount: string;
      allocationLeft: string;
      // isAllocated;
      // blockTimestamp;
    } = await contract.methods.getAddressStats(connectedAddress).call();
    return new BigNumber(addressStats.depositedAmount)
      .shiftedBy(-presalePool.decimals)
      .div(presalePool.price);
  } else if (presalePool.fromAPI) {
    const response = await fetch(
      `${presalePool.fromAPI}?address=${connectedAddress}`
    );
    const data: { amount: number } = await response.json();

    return new BigNumber(data.amount);
  }
};

export const getTotalAcquiredSDT = async (
  web3: Web3,
  connectedAddress: string
): Promise<any> => {
  const partialSDT = await Promise.all(
    presalePools
      .filter((presalePool) => !presalePool.disabled)
      .map((presalePool) => getAcquiredSDT(web3, connectedAddress, presalePool))
  );
  const totalSDT = partialSDT.reduce<BigNumber>(
    (sum, current) => sum.plus(current),
    new BigNumber(0)
  );

  return {
    total: `${totalSDT.toString(10)} SDT`,
    perPool: partialSDT,
  };
};
