import { TokenBalances, TokenPrices, TokensData } from 'models';
import {
  getBalancesSuccess,
  getGameBridgeContractSuccess,
  getNftTokenContractSuccess,
  getTokensDataSuccess,
  getTokensPricesSuccess,
  resetTokensState,
} from 'store/actions';
import { Contract } from 'web3-eth-contract';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface TokenState {
  tokensData: TokensData;
  tokensPrices: TokenPrices;
  tokensBalances: TokenBalances;
  nftTokenContract: Contract;
  gameBridgeContract: Contract;
}

export const initialTokenState: TokenState = {
  tokensData: null,
  tokensPrices: null,
  tokensBalances: null,
  nftTokenContract: null,
  gameBridgeContract: null,
};

export const tokenSlice = createSlice<
  TokenState,
  SliceCaseReducers<TokenState>,
  string
>({
  name: 'token',
  initialState: initialTokenState,
  reducers: {},
  extraReducers: {
    [getTokensDataSuccess.type]: (state, action: PayloadAction<TokensData>) => {
      state.tokensData = action.payload;
    },
    [getNftTokenContractSuccess.type]: (
      state,
      action: PayloadAction<Contract>
    ) => {
      state.nftTokenContract = action.payload;
    },
    [getGameBridgeContractSuccess.type]: (
      state,
      action: PayloadAction<Contract>
    ) => {
      state.gameBridgeContract = action.payload;
    },
    [getBalancesSuccess.type]: (state, action) => {
      state.tokensBalances = action.payload;
    },
    [getTokensPricesSuccess.type]: (
      state,
      action: PayloadAction<TokenPrices>
    ) => {
      state.tokensPrices = action.payload;
    },
    [resetTokensState.type]: (state) => {
      state = initialTokenState;
    },
  },
});
