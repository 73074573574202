import { TokenBalances, TokenPrices, TokensData } from 'models';
import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  first,
  map,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { ContractService } from 'services';
import * as tokenActions from 'store/actions/token.actions';
import { selectGasPrice } from 'store/selectors';
import { Contract } from 'web3-eth-contract';

import { Action } from '@reduxjs/toolkit';

export const getBalancesEpic = (
  actions$: Observable<Action>
): Observable<Action> =>
  actions$.pipe(
    filter(tokenActions.getBalances.match),
    map((action) => action.payload),
    switchMap(({ tokensData, connectedAddress }) =>
      ContractService.getBalances(tokensData, connectedAddress).pipe(
        first(),
        switchMap((balances: TokenBalances) => [
          tokenActions.getBalancesSuccess(balances),
        ]),
        catchError(() => [tokenActions.getBalancesFailure()])
      )
    )
  );

export const getTokensDataEpic = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(tokenActions.getTokensData.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return ContractService.getTokensContracts(
        payload.networkId,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((tokesnData: TokensData) => [
          tokenActions.getTokensDataSuccess(tokesnData),
        ]),
        catchError(() => [tokenActions.getTokensDataFailure()])
      );
    })
  );

export const getNftTokenContractEpic = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(tokenActions.getNftTokenContract.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return ContractService.getNftTokenContract(
        payload.networkId,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((nftTokenContract: Contract) => [
          tokenActions.getNftTokenContractSuccess(nftTokenContract),
        ]),
        catchError(() => [tokenActions.getNftTokenContractFailure()])
      );
    })
  );

export const getGameBridgeTokenContract = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(tokenActions.getGameBridgeContract.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return ContractService.getGameBridgeContract(
        payload.networkId,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((gameBridgeTokenContract: Contract) => [
          tokenActions.getGameBridgeContractSuccess(gameBridgeTokenContract),
        ]),
        catchError(() => [tokenActions.getGameBridgeContractFailure()])
      );
    })
  );

export const getTokensPricesEpic = (
  actions$: Observable<Action>
): Observable<Action> =>
  actions$.pipe(
    filter(tokenActions.getTokensPrices.match),
    switchMap(({ payload }) =>
      ContractService.getTokensPrices(
        payload.stableTokenPrice,
        payload.tokensData,
        payload.stableTokenData,
        payload.LPPairsContracts
      ).pipe(
        first(),
        switchMap((tokensPrices: TokenPrices) => [
          tokenActions.getTokensPricesSuccess(tokensPrices),
        ]),
        catchError(() => [tokenActions.getTokensPricesFailure()])
      )
    )
  );

export const tokenEpics = combineEpics(
  getBalancesEpic,
  getTokensDataEpic,
  getTokensPricesEpic,
  getNftTokenContractEpic,
  getGameBridgeTokenContract
);
