import { GameAccount, GameSettingsErrors, LoginResponse, Nft } from 'models';
import {
  getGameAccountsFailure,
  getGameAccountsSuccess,
  getNftsFailure,
  getNftsSuccess,
  loginFailure,
  loginSuccess,
  resetGameSettingsErrors,
  resetGameSettingsState,
  setGameSettingsErrors,
} from 'store/actions';

import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
} from '@reduxjs/toolkit';

export interface GameSettingsState {
  authToken: string;
  gameAccounts: GameAccount[];
  nfts: Nft[];
  errors: GameSettingsErrors;
}

export const initialGameSettingsState: GameSettingsState = {
  authToken: null,
  gameAccounts: null,
  nfts: null,
  errors: null,
};

export const gameSettingsSlice = createSlice<
  GameSettingsState,
  SliceCaseReducers<GameSettingsState>,
  string
>({
  name: 'gameSettings',
  initialState: initialGameSettingsState,
  reducers: {},
  extraReducers: {
    [loginSuccess.type]: (
      state: GameSettingsState,
      action: PayloadAction<LoginResponse>
    ) => {
      state.authToken = action.payload?.auth_token;
      state.gameAccounts = action.payload?.user?.gameAccounts;
    },
    [loginFailure.type]: (state: GameSettingsState) => {
      state.authToken = null;
      state.gameAccounts = [];
    },
    [getGameAccountsSuccess.type]: (
      state: GameSettingsState,
      action: PayloadAction<GameAccount[]>
    ) => {
      state.gameAccounts = action.payload;
    },
    [getGameAccountsFailure.type]: (state: GameSettingsState) => {
      state.gameAccounts = [];
    },
    [getNftsSuccess.type]: (
      state: GameSettingsState,
      action: PayloadAction<Nft[]>
    ) => {
      state.nfts = action.payload;
    },
    [getNftsFailure.type]: (state: GameSettingsState) => {
      state.nfts = [];
    },
    [setGameSettingsErrors.type]: (
      state: GameSettingsState,
      action: PayloadAction<GameSettingsErrors>
    ) => {
      state.errors = action.payload;
    },
    [resetGameSettingsErrors.type]: (state) => {
      state.errors = null;
    },
    [resetGameSettingsState.type]: (state: GameSettingsState) => {
      state = initialGameSettingsState;
    },
  },
});
