import { isProduction } from 'helpers';
import {
  AllocationPoolItemAllocationData,
  AllocationPoolItemData,
} from 'models';
import React from 'react';

interface AllocationPoolItemHeaderProps {
  allocationPoolItemData: AllocationPoolItemData;
  allocation: AllocationPoolItemAllocationData;
  decimals: number;
}

export const AllocationPoolItemHeader: React.FC<
  AllocationPoolItemHeaderProps
> = ({ allocation, allocationPoolItemData, decimals }) => (
  <div className="staking-pool-item-header">
    <div className="name">
      {allocationPoolItemData.poolItemName}
      {!allocationPoolItemData.addressCanAllocate &&
      allocationPoolItemData.isActive ? (
        <div>
          {' '}
          Not available for your wallet address, visit our{' '}
          <a
            href="https://discord.com/invite/qVuDK7Nq5m"
            className="link secondary bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord
          </a>{' '}
          for more info.
        </div>
      ) : (
        ''
      )}
      {allocationPoolItemData.isActive ? '' : <div>Sale has ended.</div>}

      {!isProduction() && <div>[DEV_ONLY TVL: {allocation?.totalSupply}]</div>}
    </div>
  </div>
);
