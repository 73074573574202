import { combineEpics } from 'redux-observable';
import {
  catchError,
  filter,
  first,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { AllocationService } from 'services';
import * as allocationActions from 'store/actions/allocation.actions';
import { selectGasPrice } from 'store/selectors';

import { Action } from '@reduxjs/toolkit';

export const getAllocationPools = (
  actions$: Observable<Action>,
  state$: Observable<any>
): Observable<Action> =>
  actions$.pipe(
    filter(allocationActions.getAllocationPools.match),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const gasPrice = selectGasPrice(state);

      return AllocationService.getAllocationPools(
        payload.networkId,
        payload.connectedAddress,
        payload.web3,
        gasPrice
      ).pipe(
        first(),
        switchMap((allocationPools) => [
          allocationActions.getAllocationPoolsSuccess(allocationPools),
        ]),
        catchError(() => [allocationActions.getAllocationPoolsFailure()])
      );
    })
  );

export const allocationEpics = combineEpics(getAllocationPools);
