import React, { PropsWithChildren } from 'react';

interface InfoCardProps {
  headerText: string;
  description: string;
}

export const InfoCard: React.FC<PropsWithChildren<InfoCardProps>> = ({
  headerText,
  description,
  children,
}) => (
  <div className="info-card">
    <div className="info-card-header">{headerText}</div>
    <div className="info-card-description">{description}</div>
    {children}
  </div>
);
